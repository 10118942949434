<template>
	<div class="questions">
		<div class="insert-title">问卷信息</div>
		<el-form :model="formData" :rules="rules" ref="fb" :hide-required-asterisk="true" class="qjc-clearfix qjc-fts-14">
			<!-- 一、基本资料 -->
			<div class="basic qjc-fl qjc-wid-100">
				<h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">一、基本资料</h3>
				
				<el-form-item label="1.客户曾经是否骨折（单选）？" prop="qnmh" class="qjc-fl qjc-wid-50">
					<el-radio-group v-model="formData.qnmh">
						<el-radio :label="1" border>没有</el-radio>
						<label>
							<el-radio :label="2" class="qnmh-radio" border>有</el-radio>
							<el-input type="number" v-if="formData.qnmh == 2" class="qntimes-input" v-model="formData.qntimes" placeholder="请填写客户曾经骨折次数"></el-input>
						</label>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="2.客户父母是否曾经髋部骨折（单选）？" prop="qnpbf" class="qjc-fl qjc-wid-50 margin-bottom-2 ">
					<el-radio-group v-model="formData.qnpbf" class="qjc-block">
						<el-radio :label="1" border>没有</el-radio>
						<el-radio :label="2" border>有</el-radio>
						<el-radio :label="3" border>不知道</el-radio>
					</el-radio-group>
				</el-form-item>

				

				<el-form-item label="3.客户的日常生活与事务决定处理与认知能力？（单选）？" prop="selfcare" class="qjc-fl qjc-wid-50">
					<el-radio-group v-model="formData.selfcare" class="qjc-block">
						<el-radio class="selfcare-radio" :label="1" border>自主决定，一直且合理</el-radio>
						<el-radio class="selfcare-radio" :label="2" border>部分自主，遇到新状况会有困难</el-radio>
						<el-radio class="selfcare-radio no-margin" :label="3" border>多数情况无法自主、或决定欠佳、或需提示</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<el-form-item label="4.股骨颈骨密度（BMD）？" prop="tscore" class="qjc-fl qjc-wid-50">
					<el-input type="number" class="tscore-input" v-model="formData.tscore" placeholder="请输入骨密度"></el-input>
				</el-form-item>
			</div>

			<!-- 二、个人病史 -->
			<div class="fb qjc-fl qjc-wid-50">
				<h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">二、个人病史</h3>

				<el-form-item label="5.客户是否患过以下疾病？" prop="hasFb">
					<el-radio-group v-model="hasFb" class="qjc-block" @change="handleFb">
						<el-radio :label="false" class="wid-96 hasFb-radio" border>否</el-radio>
						<el-radio :label="true" class="wid-96 no-margin" border>是，请选择（多选）</el-radio>
					</el-radio-group>

					<el-checkbox-group v-model="fbCheckList" class="qjc-clearfix">
						<el-checkbox label="F01" class="qjc-fl">结核病</el-checkbox>
						<!-- <el-checkbox label="F02" class="qjc-fl">恶性肿瘤</el-checkbox> -->
						<el-checkbox label="F03" class="qjc-fl">非恶性肿瘤</el-checkbox>
						<!-- <el-checkbox label="F04" class="qjc-fl">甲状腺疾病</el-checkbox> -->
						<!-- <el-checkbox label="F05" class="qjc-fl">糖尿病</el-checkbox> -->
						<el-checkbox label="F06" class="qjc-fl">营养/电解质失衡、贫血疾病</el-checkbox>
						<!-- <el-checkbox label="F07" class="qjc-fl">高血脂/高胆固醇</el-checkbox> -->
						<!-- <el-checkbox label="F08" class="qjc-fl">痛风</el-checkbox> -->
						<el-checkbox label="F09" class="qjc-fl">血液及造血相关疾病</el-checkbox>
						<el-checkbox label="F10" class="qjc-fl">认知障碍/失智症/含帕金森病</el-checkbox>
						<el-checkbox label="F11" class="qjc-fl">精神障碍</el-checkbox>
						<el-checkbox label="F12" class="qjc-fl">非精神性心理障碍</el-checkbox>
						<el-checkbox label="F13" class="qjc-fl">中枢神经系统疾病</el-checkbox>
						<el-checkbox label="F14" class="qjc-fl">末稍神经系统疾病</el-checkbox>
						<el-checkbox label="F15" class="qjc-fl">视觉损伤</el-checkbox>
						<el-checkbox label="F16" class="qjc-fl">晕眩及前庭疾病</el-checkbox>
						<el-checkbox label="F17" class="qjc-fl">听觉损伤</el-checkbox>
						<!-- <el-checkbox label="F18" class="qjc-fl">高血压</el-checkbox> -->
						<!-- <el-checkbox label="F19" class="qjc-fl">心脏病</el-checkbox> -->
						<el-checkbox label="F20" class="qjc-fl">脑血管疾病</el-checkbox>
						<el-checkbox label="F21" class="qjc-fl">动脉及毛细血管疾病</el-checkbox>
						<el-checkbox label="F22" class="qjc-fl">静脉及淋巴管疾病</el-checkbox>
						<!-- <el-checkbox label="F23" class="qjc-fl">慢性阻塞性肺部疾病</el-checkbox> -->
						<el-checkbox label="F24" class="qjc-fl">胃肠疾病</el-checkbox>
						<!-- <el-checkbox label="F25" class="qjc-fl">慢性肝脏疾病</el-checkbox> -->
						<el-checkbox label="F26" class="qjc-fl">肾病徵候群</el-checkbox>
						<el-checkbox label="F27" class="qjc-fl">前列腺疾病</el-checkbox>
						<el-checkbox label="F28" class="qjc-fl">女性生殖系统疾病</el-checkbox>
						<el-checkbox label="F29" class="qjc-fl">关节疾病</el-checkbox>
						<el-checkbox label="F30" class="qjc-fl">颈椎及背部疾病</el-checkbox>
						<el-checkbox label="F31" class="qjc-fl">风湿症</el-checkbox>
						<el-checkbox label="F32" class="qjc-fl">肌肉骨骼疾病</el-checkbox>
						<!-- <el-checkbox label="F33" class="qjc-fl">睡眠障碍</el-checkbox> -->
						<!-- <el-checkbox label="F34" class="qjc-fl">长期疼痛</el-checkbox> -->
					</el-checkbox-group>
				</el-form-item>
			</div>

			<!-- 三、个人手术史 -->
			<div class="service qjc-fl qjc-wid-50">
				<h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">三、个人手术史</h3>

				<el-form-item label="6.客户是否需要医护服务？" prop="hasService">
					<el-radio-group v-model="hasService" class="qjc-block" @change="handleService">
						<el-radio :label="false" class="wid-96 hasFb-radio" border>否</el-radio>
						<el-radio :label="true" class="wid-96 no-margin" border>是，请选择（多选）</el-radio>
					</el-radio-group>

					<el-checkbox-group v-model="serviceCheckList" class="qjc-clearfix">
						<el-checkbox label="1" class="qjc-fl">就医陪伴</el-checkbox>
						<el-checkbox label="2" class="qjc-fl">专业复健服务</el-checkbox>
						<el-checkbox label="3" class="qjc-fl">医疗咨询服务</el-checkbox>
						<el-checkbox label="4" class="qjc-fl">健康管理与促进咨询服务</el-checkbox>
						<el-checkbox label="5" class="qjc-fl">营养或健康食品咨询服务</el-checkbox>
						<el-checkbox label="6" class="qjc-fl">用药咨询服务</el-checkbox>
						<el-checkbox label="7" class="qjc-fl">健康检查咨询服务</el-checkbox>
						<el-checkbox label="8" class="qjc-fl">陪伴或协助社交活动</el-checkbox>
						<el-checkbox label="9" class="qjc-fl">陪伴或协助运动</el-checkbox>
						<el-checkbox label="10" class="qjc-fl">喘息服务</el-checkbox>
						<el-checkbox label="11" class="qjc-fl">居家清洁卫生服务</el-checkbox>
						<el-checkbox label="12" class="qjc-fl">洗衣服务</el-checkbox>
						<el-checkbox label="13" class="qjc-fl">居家安全设计咨询服务</el-checkbox>
						<el-checkbox label="14" class="qjc-fl">供餐服务</el-checkbox>
						<el-checkbox label="15" class="qjc-fl">探视与陪伴服务</el-checkbox>
						<el-checkbox label="16" class="qjc-fl">居家照护人力提供服务</el-checkbox>
						<el-checkbox label="17" class="qjc-fl">保险理财咨询服务</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</div>

			<!-- <el-form-item class="qjc-fl qjc-wid-100 qjc-texta-c"><el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button></el-form-item> -->
		</el-form>
		
		<div class="footer-btn">
			<el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">上一步</el-button>
			<el-button @click="next" class="next qjc-fts-16 qjc-block">下一步</el-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'insertFb',
	data() {
		//验证
		// 是否患有一下疾病
		var diseaseVerify = (rule, value, callback) => {
			if (this.hasDisease === '') {
				callback(new Error('请选择客户是否患有以下疾病'));
			} else if (this.hasDisease) {
				if (value.filter(item => item.LEVEL != '0').length > 0) {
					callback();
				} else {
					callback(new Error('请选择服药情况'));
				}
			} else {
				callback();
			}
		};
		// 手术史
		var operVerify = (rule, value, callback) => {
			if (this.hasOper === '') {
				callback(new Error('请选择客户是否做过下列手术'));
			} else if (this.hasOper) {
				if (value.length > 0) {
					callback();
				} else {
					callback(new Error('请选择'));
				}
			} else {
				callback();
			}
		};
		// 家族病史
		var famdisVerify = (rule, value, callback) => {
			if (this.hasFamdis === '') {
				callback(new Error('请选择客户的直系亲属是否存在下列疾病'));
			} else if (this.hasFamdis) {
				if (value.length > 0) {
					callback();
				} else {
					callback(new Error('请选择'));
				}
			} else {
				callback();
			}
		};
		
		return {
			rules: {
				area: [{ required: true, message: '请选择客户的居住地' }],
				marry: [{ required: true, message: '请选择客户的婚姻状况' }],
				childcnt: [{ required: true, message: '请选择客户的子女数目' }],
				drink: [{ required: true, message: '请选择客户的喝酒情况' }],
				smoke: [{ required: true, message: '请选择客户是否吸烟' }],
				habeat: [{ required: true, message: '请选择客户的用餐习惯' }],
				vege: [{ required: true, message: '请选择客户是否为全日素食者' }],
				habsport: [{ required: true, message: '请选择客户的运动习惯' }],
				sleep: [{ required: true, message: '请选择客户是否存在睡眠障碍' }],
				qnmh: [{ required: true, message: '请选择客户曾经是否存骨折' }],
				qnpbf: [{ required: true, message: '请选择客户父母是否曾经髋部骨折' }],
				selfcare: [{ required: true, message: '请选择客户生活自主认知能力' }],
				tscore: [{ required: true, message: '请填写客户骨密度值' }],
				qntimes: [{ required: false, message: '请填写客户曾经骨折次数' }],
				disease: [{ validator: diseaseVerify, trigger: 'blur' }],
				oper: [{ validator: operVerify, trigger: 'blur' }],
				famdis: [{ validator: famdisVerify, trigger: 'blur' }],
			},

			// 客户居住地
			areaList: [
				{ text: '江苏', code: '3200' },
				{ text: '上海', code: '3100' },
				{ text: '北京', code: '1100' },
				{ text: '天津', code: '1200' },
				{ text: '浙江', code: '3300' },
				{ text: '广东', code: '4400' },
				{ text: '辽宁', code: '2100' },
				{ text: '山东', code: '3700' },
				{ text: '内蒙古', code: '1500' },
				{ text: '重庆', code: '5000' },
				{ text: '福建', code: '3500' },
				{ text: '海南', code: '4600' },
				{ text: '湖北', code: '4200' },
				{ text: '湖南', code: '4300' },
				{ text: '陝西', code: '6100' },
				{ text: '河南', code: '4100' },
				{ text: '黑龙江', code: '2300' },
				{ text: '青海', code: '6300' },
				{ text: '云南', code: '5300' },
				{ text: '安徽', code: '3400' },
				{ text: '四川', code: '5100' },
				{ text: '贵州', code: '5200' },
				{ text: '甘肃', code: '6200' },
				{ text: '江西', code: '3600' },
				{ text: '吉林', code: '2200' },
				{ text: '山西', code: '1400' },
				{ text: '河北', code: '1300' },
				{ text: '宁夏', code: '6400' },
				{ text: '广西', code: '4500' },
				{ text: '新疆', code: '6500' },
				{ text: '西藏', code: '5400' },
				{ text: '香港', code: '4401' },
				{ text: '台湾', code: '0000' }
			],

			diseaseNames: [
				{ KDIS: 'K01', name: '高血压' },
				{ KDIS: 'K02', name: '高血脂' },
				{ KDIS: 'K03', name: '糖尿病' },
				{ KDIS: 'K04', name: '慢性肝病' },
				{ KDIS: 'K05', name: '慢性肾脏病' },
				{ KDIS: 'K06', name: '癌症' },
				{ KDIS: 'K07', name: '肝硬化' },
				{ KDIS: 'K08', name: '慢性阻塞性肺炎' },
				{ KDIS: 'K09', name: '关节炎' },
				{ KDIS: 'K10', name: '心脏病' },
				{ KDIS: 'K11', name: '脑中风' },
				{ KDIS: 'K12', name: '胃肠溃疡' },
				{ KDIS: 'K13', name: '睡眠障碍' },
				//新增
				{ KDIS: 'K14', name: '甲状腺疾病' },
				{ KDIS: 'K15', name: '贫血' },
				{ KDIS: 'K16', name: '疼痛' },
				{ KDIS: 'K17', name: '哮喘' },
				{ KDIS: 'K18', name: '痛风' },
				{ KDIS: 'K19', name: '痔疮' }
			], //kids所对应疾病
			fbdiseaseList: [
				{"fdis": "F00", "level": "1"},
				{"fdis": "F01", "level": "0"},
				{"fdis": "F02", "level": "0"},
				{"fdis": "F03", "level": "0"},
				{"fdis": "F04", "level": "0"},
				{"fdis": "F05", "level": "0"},
				{"fdis": "F06", "level": "0"},
				{"fdis": "F07", "level": "0"},
				{"fdis": "F08", "level": "0"},
				{"fdis": "F09", "level": "0"},
				{"fdis": "F10", "level": "0"},
				{"fdis": "F11", "level": "0"},
				{"fdis": "F12", "level": "0"},
				{"fdis": "F13", "level": "0"},
				{"fdis": "F14", "level": "0"},
				{"fdis": "F15", "level": "0"},
				{"fdis": "F16", "level": "0"},
				{"fdis": "F17", "level": "0"},
				{"fdis": "F18", "level": "0"},
				{"fdis": "F19", "level": "0"},
				{"fdis": "F20", "level": "0"},
				{"fdis": "F21", "level": "0"},
				{"fdis": "F22", "level": "0"},
				{"fdis": "F23", "level": "0"},
				{"fdis": "F24", "level": "0"},
				{"fdis": "F25", "level": "0"},
				{"fdis": "F26", "level": "0"},
				{"fdis": "F27", "level": "0"},
				{"fdis": "F28", "level": "0"},
				{"fdis": "F29", "level": "0"},
				{"fdis": "F30", "level": "0"},
				{"fdis": "F31", "level": "0"},
				{"fdis": "F32", "level": "0"},
				{"fdis": "F33", "level": "0"},
				{"fdis": "F34", "level": "0"}
			],
			serviceList: [
				{"svc": "V01", "need": "1"},
				{"svc": "V02", "need": "1"},
				{"svc": "V03", "need": "1"},
				{"svc": "V04", "need": "1"},
				{"svc": "V05", "need": "1"},
				{"svc": "V06", "need": "1"},
				{"svc": "V07", "need": "1"},
				{"svc": "V08", "need": "1"},
				{"svc": "V09", "need": "1"},
				{"svc": "V10", "need": "1"},
				{"svc": "V11", "need": "1"},
				{"svc": "V12", "need": "1"},
				{"svc": "V13", "need": "1"},
				{"svc": "V14", "need": "1"},
				{"svc": "V15", "need": "1"},
				{"svc": "V16", "need": "1"},
				{"svc": "V17", "need": "1"},
			],
			hasDisease: '', //是否患有指定疾病
			hasOper: '', //是否有手术史
			hasFamdis: '', //是否有家族病史,
			hasFb: false,
			hasService: false,
			fbCheckList: [],
			serviceCheckList: [],
		};
	},
	props: ['type', 'formData'],
	mounted() {
		//数据修改时  根据已有数据显示内容
		if (this.type == 'update') {
			console.log(this.formData)
			//个人病史
			var dis = this.formData.disease.filter(d => d.level != 0);
			if (dis && dis.length > 0) {
				this.hasDisease = true;
			} else {
				this.hasDisease = false;
			}

			//个人手术史
			if (this.formData.oper === '') {
				// 若为空，改为空数组
				this.formData.oper = [];
			}
			if (this.formData.oper && this.formData.oper.length > 0) {
				this.hasOper = true;
			} else {
				this.hasOper = false;
			}

			//家族病史
			if (this.formData.famdis === '') {
				// 若为空，改为空数组
				this.formData.famdis = [];
			}
			if (this.formData.famdis && this.formData.famdis.length > 0) {
				this.hasFamdis = true;
			} else {
				this.hasFamdis = false;
			}
			
			if(this.formData.fbdisease) {
				if(this.formData.fbdisease[0].level == 1) {
					this.hasFb = false;
				} else {
					var fbdis = this.formData.fbdisease.filter((d, index) => (index != 0 && d.level != 0 && d.fdis != 'F02' && d.fdis != 'F04' && d.fdis != 'F05' && d.fdis != 'F07' && d.fdis != 'F08' && d.fdis != 'F18' && d.fdis != 'F19' && d.fdis != 'F23' && d.fdis != 'F25' && d.fdis != 'F33' && d.fdis != 'F34'));
					fbdis.map((item, index) => {
						this.fbdiseaseList.map((fb, fbIndex) => {
							if(item.fdis == fb.fdis) {
								this.hasFb = true;
								this.fbCheckList.push(fb.fdis)
							}
						})
					})
				}
				
				var servicedis = this.formData.service.filter(d => d.need != 1);
				if (servicedis && servicedis.length > 0) {
					this.hasService = true;
					servicedis.map((item, index) => {
						this.serviceList.map((ser, serIndex) => {
							if(item.svc == ser.svc) {
								this.serviceCheckList.push(serIndex + 1 + '')
							}
						})
					})
				} else {
					this.hasService = false;
				}
			}
		} else {
			if(this.formData.fbdisease) {
				if(this.formData.fbdisease[0].level == 1) {
					this.hasFb = false;
				} else {
					var fbdis = this.formData.fbdisease.filter((d, index) => (index != 0 && d.level != 0 && d.fdis != 'F02' && d.fdis != 'F04' && d.fdis != 'F05' && d.fdis != 'F07' && d.fdis != 'F08' && d.fdis != 'F18' && d.fdis != 'F19' && d.fdis != 'F23' && d.fdis != 'F25' && d.fdis != 'F33' && d.fdis != 'F34'));
					fbdis.map((item, index) => {
						this.fbdiseaseList.map((fb, fbIndex) => {
							if(item.fdis == fb.fdis) {
								this.hasFb = true;
								this.fbCheckList.push(fb.fdis)
							}
						})
					})
				}
				
				var servicedis = this.formData.service.filter(d => d.need != 1);
				if (servicedis && servicedis.length > 0) {
					this.hasService = true;
					servicedis.map((item, index) => {
						this.serviceList.map((ser, serIndex) => {
							if(item.svc == ser.svc) {
								this.serviceCheckList.push(serIndex + 1 + '')
							}
						})
					})
				} else {
					this.hasService = false;
				}
			}
		}
		
		
	},
	watch: {
		fbCheckList(newVal, oldVal) {
			if(newVal && newVal.length > 0) {
				this.hasFb = true
			} else {
				this.hasFb = false
			}
		},
		serviceCheckList(newVal, oldVal) {
			if(newVal && newVal.length > 0) {
				this.hasService = true
			} else {
				this.hasService = false
			}
		},
	},
	methods: {
		handleFb(value) {
			if(!value){// 不存在指定疾病 清0
				this.fbCheckList = []
			}
		},
		handleService(value) {
			if(!value){// 不存在指定疾病 清0
				this.serviceCheckList = []
			}
		},
		// 下一步
		next() {
			let list = [...this.fbdiseaseList]
			let serlist = [...this.serviceList]
			let disList = [...this.formData.disease]
			let fbList = []
			let hasFd = false
			
			if(this.formData.qnmh == '2'){
				if(this.formData.qntimes <= 0){
					// Toast('请填写客户曾经骨折次数')
					this.$myAlert({
						type: 'fail',
						content: '请填写客户曾经骨折次数'
					});
					return
				}
			}
			
			disList.forEach((item, num) => {
				if(item.level != '0') {
					switch(item.kdis) {
						case 'K01' :
						fbList.push('F18')
						hasFd = true
						break;
						case 'K02' :
						fbList.push('F07')
						hasFd = true
						break;
						case 'K03' :
						fbList.push('F05')
						hasFd = true
						break;
						case 'K04' :
						fbList.push('F25')
						hasFd = true
						break;
						case 'K06' :
						fbList.push('F02')
						hasFd = true
						break;
						case 'K08' :
						fbList.push('F23')
						hasFd = true
						break;
						case 'K10' :
						fbList.push('F19')
						hasFd = true
						break;
						case 'K13' :
						fbList.push('F33')
						hasFd = true
						break;
						case 'K19' :
						fbList.push('F04')
						hasFd = true
						break;
						case 'K15' :
						fbList.push('F34')
						hasFd = true
						break;
						case 'K17' :
						fbList.push('F08')
						hasFd = true
						break;
					}
				}
			})
			if(this.hasFb) {
				if(this.fbCheckList.length <= 0) {
					this.$myAlert({
						type: 'fail',
						content: '请选择客户患病情况'
					});
					return
				}
				this.fbCheckList.map((item, index) => {
					list.map((fb, fbIndex) => {
						if(item == fb.fdis) {
							fb.level = 1
						}
					})
				})
				// this.formData.fbdisease = list
				list[0].level = 0
			}
			if(hasFd) {
				list.forEach((item, num) => {
					fbList.forEach((item2, num2) => {
						if (item.fdis == item2) {
							list[num].level = '1'
						}
					})
				})
				list[0].level = '0'
			}
			
			this.formData.fbdisease = list
			
			if(this.hasService) {
				if(this.serviceCheckList.length <= 0) {
					this.$myAlert({
						type: 'fail',
						content: '请选择客户是否需要医护服务'
					});
					return
				}
				this.serviceCheckList.map((item, index) => {
					serlist.map((ser, serIndex) => {
						if(item == serIndex + 1) {
							ser.need = '2'
						}
					})
				})
				this.formData.service = serlist
			} else {
				this.formData.service = serlist
			}
		
			this.$refs.fb.validate((isSuc, err) => {
				if (isSuc) {
					this.$emit('checkTab', 'Biochemical');
				}
			});
		},
		
		handlePrev() {
			this.$emit('checkTab', 'Bodily');
		},

		// 获取对应KDIS疾病名称
		// getDisName(KDIS) {
		// 	let name = '';
		// 	this.diseaseNames.forEach(item => {
		// 		if (item.KDIS == KDIS) {
		// 			name = item.name;
		// 			return;
		// 		}
		// 	});
		// 	return name;
		// },

		// checkDis(value) {
		// 	if (!value) {
		// 		// 不存在指定疾病 清0
		// 		this.formData.disease.forEach(item => {
		// 			item.LEVEL = '0';
		// 		});
		// 	}
		// },

		// 切换服药状态 参数：row:当前所属疾病data   LEVEL: LEVEL将要改变的值
		// levelChange(row, LEVEL) {
		// 	if (row.LEVEL == LEVEL) {
		// 		row.LEVEL = '0';
		// 	} else {
		// 		row.LEVEL = LEVEL;
		// 	}
		// }
	}
};
</script>

<style scoped>
.el-form >>> .el-form-item__label {
	width: 100%;
	text-align: left;
	color: #40444d;
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 16px;
}
.area >>> .el-form-item__label {
	width: 111px;
}
.el-form >>> .el-form-item__content {
	line-height: 1;
}
.area >>> input {
	width: 190px;
	height: 32px;
	font-size: 14px;
	line-height: 16px;
	padding: 9px 12px;
}
.area >>> .el-input .el-select__caret {
	font-size: 14px;
	line-height: 32px;
}
.el-form >>> .el-radio__label {
	font-size: 14px;
	color: #777f8f;
}
.el-form >>> .diseaseTd {
	color: #777f8f;
	background-color: #f2f4fd;
	padding: 10px 16px;
	line-height: 14px;
	border: 0.5px solid #fff;
}
.el-table >>> .el-checkbox__inner {
	border-color: #777f8f;
}
.el-table >>> .cell {
	padding: 0;
	line-height: 14px;
}
.el-table >>> .el-table__body,
.el-table >>> .el-table__footer,
.el-table >>> .el-table__header {
	table-layout: auto;
}
.el-table >>> .name {
	width: 153px;
}
.el-table >>> .no {
	width: 105px;
}
.el-table >>> .acc {
	width: 120px;
}
.el-table >>> .often {
	width: 136px;
}
.oper >>> .el-checkbox__label,
.famdis >>> .el-checkbox__label {
	font-size: 14px;
}
.oper >>> .el-checkbox__inner,
.famdis >>> .el-checkbox__inner {
	border-color: #777f8f;
}
.questions >>> .el-checkbox__input.is-disabled + span.el-checkbox__label {
	color: #777f8f;
	font-weight: normal;
	font-size: 14px;
}

.margin-bottom-2 {
	height: 80px;
}
.questions >>> .el-radio.is-bordered {
	height: 34px;
	line-height: 32px;
	padding: 0 12px;
	font-size: 14px;
	color: #525866;
	border: 1px solid #CED4F0;
}
.questions >>> .el-input__inner {
	height: 34px;
	line-height: 34px;
}
.questions >>> .el-form .el-form-item .el-radio {
	min-width: 130px;
}
</style>
<style lang="scss" scoped>
.questions {
	padding-top: 50px;
	
	.insert-title {
		position: relative;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #293033;
		line-height: 16px;
		padding-bottom: 15px;
		border-bottom: 1px solid #E5E8F5;
		margin-bottom: 32px;
		
		&::before{
			position: absolute;
			left: -24px;
			top: 0;
			content: '';
			width: 4px;
			height: 20px;
			background-color: #6883FB;
		}
	}

	.el-form {
		h3 {
			line-height: 16px;
			margin-bottom: 24px;
		}
		.basic {
			margin-bottom: 40px;

			.el-form-item {
				width: 50%;
			}
		}
		.el-form-item {
			margin-bottom: 25px;

			.area-label {
				line-height: 32px;
			}
			.el-radio {
				margin-bottom: 15px;
				margin-right: 0;
			}

			&.sleep {
				margin-top: -29px;
			}
			.el-table {
				width: 96%;
				margin-left: 24px;
			}

			.next {
				width: 200px;
				height: 48px;
				margin-top: 47px;
			}

			.tscore-input {
				width: 300px;
			}

			.qntimes-input {
				width: 200px;
				
			}
		}
		.el-radio {
			font-weight: normal;
		}
		.oper,
		.fb,
		.service,
		.famdis {
			.el-checkbox-group {
				width: 96%;
				background-color: #f2f4fd;
				// margin-left: 0.24px;

				.el-checkbox {
					width: 33.33%;
					line-height: 14px;
					margin-right: 0;
					padding: 10px 16px;
					border: 0.5px solid #fff;
					color: #777f8f;
				}
			}
		}
		.oper {
			margin-bottom: 88px;
		}
	}
	.no-margin {
		margin-left: 0 !important;
	}
	
	.qnmh-radio {
		margin-left: 15px;
	}
	
	.wid-96 {
		width: 96%;
	}
	
	.hasFb-radio {
		width: 96%;
		padding-right: 20px;
	}
	
	.footer-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
	}
			
	.next{
		width: 96px;
		height: 34px;
		border-radius: 17px;
		border: none;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #fff;
	}
	.prev {
		width: 96px;
		height: 34px;
		border-radius: 17px;
		border: 1px solid #6883FB;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #6883FB;
		background-color: #fff;
		margin-right: 24px;
	}
}

.selfcare-radio {
	min-width: 273px !important;
}
</style>
